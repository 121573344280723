<template>
  <div class="container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ pageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <el-alert type="success" :closable="false">
      <template slot="title">
        <div style="font-size: 16px; padding: 12px; line-height: 1.8;">
          {{
            `${userMethods.displayName(user.first_name, user.last_name)} ${$t(
              "practice.alreadyFinishedPractice1"
            )} ${practice.exam.title} ${$t(
              "practice.alreadyFinishedPractice2"
            )}`
          }}<br />
          {{ `${$t("practice.finishAt")}${userExam.finishAt}` }}
        </div>
      </template>
    </el-alert>
    <hr class="separate-line" />
    <el-row type="flex" justify="space-between">
      <el-col style="text-align: center" :span="6">
        <div style="margin-bottom: 12px">
          <b v-if="userExam.correctPercent === 100"
            >{{ $t("practice.score") }}Perfect!</b
          >
          <b
            v-if="
              userExam.correctPercent >= 100 && userExam.correctPercent < 100
            "
            >{{ $t("practice.score") }}Great!</b
          >
          <b
            v-if="userExam.correctPercent >= 70 && userExam.correctPercent < 85"
            >{{ $t("practice.score") }}Work harder :)</b
          >
          <b v-if="userExam.correctPercent > 0 && userExam.correctPercent < 70"
            >{{ $t("practice.score") }}Unsatisfactory :(</b
          >
          <b v-if="userExam.correctPercent === 0"
            >{{ $t("practice.score") }}Unsatisfactory :(</b
          >
        </div>
        <img
          v-if="userExam.correctPercent === 100"
          src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/practice/correct_percent5.gif"
          alt="evaluation"
          width="200"
        />
        <img
          v-if="userExam.correctPercent >= 100 && userExam.correctPercent < 100"
          src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/practice/correct_percent4.gif"
          alt="evaluation"
          width="200"
        />
        <img
          v-if="userExam.correctPercent >= 70 && userExam.correctPercent < 85"
          src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/practice/correct_percent3.gif"
          alt="evaluation"
          width="200"
        />
        <img
          v-if="userExam.correctPercent > 0 && userExam.correctPercent < 70"
          src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/practice/correct_percent2.gif"
          alt="evaluation"
          width="200"
        />
        <img
          v-if="userExam.correctPercent === 0"
          src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/practice/correct_percent1.gif"
          alt="evaluation"
          width="200"
        />
      </el-col>
      <el-col style="text-align: center" :span="6">
        <div style="margin-bottom: 12px">
          <b>{{ $t("practice.practiceResult") }}</b>
        </div>
        <el-progress
          type="circle"
          color="#42a16a"
          :width="200"
          :stroke-width="20"
          :percentage="userExam.correctPercent"
          :format="
            () =>
              `${userExam.correctQuestions}/${userExam.totalQuestions} (${userExam.correctPercent}%)`
          "
        />
      </el-col>
      <el-col style="text-align: center" :span="6">
        <div style="margin-bottom: 12px">
          <b>{{ $t("practice.practiceTime") }}</b>
        </div>
        <el-progress
          type="circle"
          color="#42a16a"
          :width="200"
          :stroke-width="20"
          :percentage="100"
          :format="
            () => instant.formatSecondToMMSS(userExam.other_status[0].progress_time)
          "
        />
      </el-col>
    </el-row>
    <template v-if="practice.show !== 'COMPLETION_ONLY' || isRoleAdmin()">
      <hr class="separate-line" />
      <h2>{{ $t("practice.questionDetail") }}</h2>
      <el-row>
        <el-col :span="24">
          <el-table
            style="width: 100%"
            :data="[
              { row: 1, title: $t('practice.answer') },
              { row: 2, title: $t('practice.yourAnswer') }
            ]"
          >
            <el-table-column label="#" prop="title" width="120" fixed />
            <el-table-column
              header-align="center"
              :key="question.order"
              v-for="question in practice.exam.questions"
            >
              <template slot="header">
                {{ question.order }}
              </template>
              <template slot-scope="scope">
                <div
                  v-if="scope.row.row === 1"
                  @click="
                    () => {
                      currentQuestion = question;
                    }
                  "
                  :class="
                    currentQuestion && currentQuestion.order === question.order
                      ? 'correctAnswerActiveText'
                      : 'correctAnswerText'
                  "
                >
                  <b>{{
                    question.question.answers
                      .map(answer => answer.answers.join(","))
                      .join(",")
                  }}</b>
                </div>
                <div
                  v-if="scope.row.row === 2"
                  style="width: 100%; height: 100%; color: #a94442; text-align: center"
                >
                  <b>
                    {{
                      parsingAnswer(question).join(",") ||
                        (userExam.is_finished ? "*" : "")
                    }}
                  </b>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-row v-if="currentQuestion" type="flex" justify="space-between">
            <el-col v-if="!isMultiple" class="border" :span="12">
              <ViewPassage
                :key="currentQuestion"
                :passage="currentQuestion.question.passage"
                :type="currentQuestion.question_type"
                :section="currentQuestion.question.subject.name"
              />
            </el-col>
            <el-col class="border" :span="isMultiple ? 24 : 12">
              <Question
                :testType="currentQuestion.question_type"
                :question="currentQuestion.question"
                :disabledAction="true"
                :disableDelete="true"
                :displayCorrectAnswer="
                  practice.show === 'CORRECTNESS_AND_EXPLANATION' ||
                    isRoleAdmin()
                "
              />
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </template>
  </div>
</template>

<script>
import moment from "moment";
import { user, instant } from "@ivy-way/material";
import Breadcrumb from "@/components/Breadcrumb";
import ViewPassage from "@/components/tests/ViewPassage.vue";
import Question from "@/components/tests/Question.vue";
import practiceApi from "@/apis/practices";
import roleMixin from "@/mixins/role";
import questionAdapter from "@/components/practices/QuestionAdapter";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: { Breadcrumb, ViewPassage, Question },
  mixins: [roleMixin],
  data() {
    return {
      practice: null,
      userExam: null,
      currentQuestion: null
    };
  },
  computed: {
    instant() {
      return instant;
    },
    userMethods() {
      return user;
    },
    pageTitle() {
      return this.practice ? `${this.practice.exam.title} Result` : "";
    },
    isMultiple() {
      return this.currentQuestion.question.type === "multiple";
    }
  },
  async created() {
    this.user = this.$store.getters["user/getProfile"];
    await this.fetchPracticeExamResult();
  },
  methods: {
    async fetchPracticeExamResult() {
      const {
        user_exam,
        practice,
        correct_percent,
        total_questions,
        correct_questions
      } = await practiceApi.fetchPracticeExamResult(
        this.$route.params.userExamId
      );
      this.userExam = {
        ...user_exam,
        finishAt: moment(user_exam.updated_at).format("YYYY-MM-DD HH:mm:ss"),
        correctPercent: correct_percent,
        correctQuestions: correct_questions,
        totalQuestions: total_questions
      };
      this.practice = {
        ...practice,
        exam: {
          ...practice.exam,
          questions: practice.exam.questions.map(question => ({
            ...questionAdapter(question),
            wroteAnswers: this.getExamQuestion(question.id).answers
          }))
        }
      };
    },
    getExamQuestion(questionId) {
      return this.userExam.exam_answers.find(
        answer => answer.exam_question_id === questionId
      );
    },
    parsingAnswer(question) {
      switch (question.question_type) {
        case "toefl": {
          return question.wroteAnswers.map(wroteAnswer => {
            const option = question.question.options.find(
              option => String(option.id) === String(wroteAnswer)
            );
            return option.letter;
          });
        }
        default:
          return question.wroteAnswers;
      }
    }
  }
};
</script>

<style>
.correctAnswerText {
  width: 100%;
  height: 100%;
  color: #3c763d;
  text-align: center;
  cursor: pointer;
}

.correctAnswerActiveText {
  width: 100%;
  height: 100%;
  background: #3c763d;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}

.border {
  border: 1px solid #ccc;
  padding: 2%;
  overflow: auto;
  margin: 0 15px;
  max-height: 600px;
}
</style>
