var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('Breadcrumb',[_c('template',{slot:"restPage"},[_c('el-breadcrumb-item',[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")])],1)],2),_c('el-alert',{attrs:{"type":"success","closable":false}},[_c('template',{slot:"title"},[_c('div',{staticStyle:{"font-size":"16px","padding":"12px","line-height":"1.8"}},[_vm._v(" "+_vm._s(`${_vm.userMethods.displayName(_vm.user.first_name, _vm.user.last_name)} ${_vm.$t( "practice.alreadyFinishedPractice1" )} ${_vm.practice.exam.title} ${_vm.$t( "practice.alreadyFinishedPractice2" )}`)),_c('br'),_vm._v(" "+_vm._s(`${_vm.$t("practice.finishAt")}${_vm.userExam.finishAt}`)+" ")])])],2),_c('hr',{staticClass:"separate-line"}),_c('el-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('el-col',{staticStyle:{"text-align":"center"},attrs:{"span":6}},[_c('div',{staticStyle:{"margin-bottom":"12px"}},[(_vm.userExam.correctPercent === 100)?_c('b',[_vm._v(_vm._s(_vm.$t("practice.score"))+"Perfect!")]):_vm._e(),(
            _vm.userExam.correctPercent >= 100 && _vm.userExam.correctPercent < 100
          )?_c('b',[_vm._v(_vm._s(_vm.$t("practice.score"))+"Great!")]):_vm._e(),(_vm.userExam.correctPercent >= 70 && _vm.userExam.correctPercent < 85)?_c('b',[_vm._v(_vm._s(_vm.$t("practice.score"))+"Work harder :)")]):_vm._e(),(_vm.userExam.correctPercent > 0 && _vm.userExam.correctPercent < 70)?_c('b',[_vm._v(_vm._s(_vm.$t("practice.score"))+"Unsatisfactory :(")]):_vm._e(),(_vm.userExam.correctPercent === 0)?_c('b',[_vm._v(_vm._s(_vm.$t("practice.score"))+"Unsatisfactory :(")]):_vm._e()]),(_vm.userExam.correctPercent === 100)?_c('img',{attrs:{"src":"https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/practice/correct_percent5.gif","alt":"evaluation","width":"200"}}):_vm._e(),(_vm.userExam.correctPercent >= 100 && _vm.userExam.correctPercent < 100)?_c('img',{attrs:{"src":"https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/practice/correct_percent4.gif","alt":"evaluation","width":"200"}}):_vm._e(),(_vm.userExam.correctPercent >= 70 && _vm.userExam.correctPercent < 85)?_c('img',{attrs:{"src":"https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/practice/correct_percent3.gif","alt":"evaluation","width":"200"}}):_vm._e(),(_vm.userExam.correctPercent > 0 && _vm.userExam.correctPercent < 70)?_c('img',{attrs:{"src":"https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/practice/correct_percent2.gif","alt":"evaluation","width":"200"}}):_vm._e(),(_vm.userExam.correctPercent === 0)?_c('img',{attrs:{"src":"https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/practice/correct_percent1.gif","alt":"evaluation","width":"200"}}):_vm._e()]),_c('el-col',{staticStyle:{"text-align":"center"},attrs:{"span":6}},[_c('div',{staticStyle:{"margin-bottom":"12px"}},[_c('b',[_vm._v(_vm._s(_vm.$t("practice.practiceResult")))])]),_c('el-progress',{attrs:{"type":"circle","color":"#42a16a","width":200,"stroke-width":20,"percentage":_vm.userExam.correctPercent,"format":() =>
            `${_vm.userExam.correctQuestions}/${_vm.userExam.totalQuestions} (${_vm.userExam.correctPercent}%)`}})],1),_c('el-col',{staticStyle:{"text-align":"center"},attrs:{"span":6}},[_c('div',{staticStyle:{"margin-bottom":"12px"}},[_c('b',[_vm._v(_vm._s(_vm.$t("practice.practiceTime")))])]),_c('el-progress',{attrs:{"type":"circle","color":"#42a16a","width":200,"stroke-width":20,"percentage":100,"format":() => _vm.instant.formatSecondToMMSS(_vm.userExam.other_status[0].progress_time)}})],1)],1),(_vm.practice.show !== 'COMPLETION_ONLY' || _vm.isRoleAdmin())?[_c('hr',{staticClass:"separate-line"}),_c('h2',[_vm._v(_vm._s(_vm.$t("practice.questionDetail")))]),_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":[
            { row: 1, title: _vm.$t('practice.answer') },
            { row: 2, title: _vm.$t('practice.yourAnswer') }
          ]}},[_c('el-table-column',{attrs:{"label":"#","prop":"title","width":"120","fixed":""}}),_vm._l((_vm.practice.exam.questions),function(question){return _c('el-table-column',{key:question.order,attrs:{"header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.row === 1)?_c('div',{class:_vm.currentQuestion && _vm.currentQuestion.order === question.order
                    ? 'correctAnswerActiveText'
                    : 'correctAnswerText',on:{"click":() => {
                    _vm.currentQuestion = question;
                  }}},[_c('b',[_vm._v(_vm._s(question.question.answers .map(answer => answer.answers.join(",")) .join(",")))])]):_vm._e(),(scope.row.row === 2)?_c('div',{staticStyle:{"width":"100%","height":"100%","color":"#a94442","text-align":"center"}},[_c('b',[_vm._v(" "+_vm._s(_vm.parsingAnswer(question).join(",") || (_vm.userExam.is_finished ? "*" : ""))+" ")])]):_vm._e()]}}],null,true)},[_c('template',{slot:"header"},[_vm._v(" "+_vm._s(question.order)+" ")])],2)})],2)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":24}},[(_vm.currentQuestion)?_c('el-row',{attrs:{"type":"flex","justify":"space-between"}},[(!_vm.isMultiple)?_c('el-col',{staticClass:"border",attrs:{"span":12}},[_c('ViewPassage',{key:_vm.currentQuestion,attrs:{"passage":_vm.currentQuestion.question.passage,"type":_vm.currentQuestion.question_type,"section":_vm.currentQuestion.question.subject.name}})],1):_vm._e(),_c('el-col',{staticClass:"border",attrs:{"span":_vm.isMultiple ? 24 : 12}},[_c('Question',{attrs:{"testType":_vm.currentQuestion.question_type,"question":_vm.currentQuestion.question,"disabledAction":true,"disableDelete":true,"displayCorrectAnswer":_vm.practice.show === 'CORRECTNESS_AND_EXPLANATION' ||
                  _vm.isRoleAdmin()}})],1)],1):_vm._e()],1)],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }